import React from 'react';
import {
  StripCTA,
  StripContainer,
  StripIconInfoWrapper,
  StripOfferIcon,
} from '../Common';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

interface BasicPromoStripProps {
  promoId?: any;
  primaryText?: string;
  secondaryText?: string;
  ctaTextOrIcon?: any;
  onCtaClick?: (id: any) => void;
  bgColor?: string;
  showHoverEffect?: boolean;
  customClasses?: string;
  primaryTextClasses?: string;
  secondaryTextClasses?: string;
}

const BasicPromoStrip: React.FC<BasicPromoStripProps> = ({
  promoId = null,
  primaryText = '',
  secondaryText = '',
  ctaTextOrIcon = null,
  onCtaClick = null,
  bgColor = '#ffffff',
  showHoverEffect = true,
  customClasses = '',
  primaryTextClasses = '',
  secondaryTextClasses = '',
}) => {
  return (
    <StripContainer
      showHoverEffect={showHoverEffect}
      bgColor={bgColor}
      customClasses={customClasses}
      onClick={() => {
        !IS_DESKTOP && onCtaClick(promoId);
      }}
    >
      <StripIconInfoWrapper>
        <StripOfferIcon />
        <div className="tw-flex tw-flex-col tw-gap-[2px]">
          <p className={`tw-m-0 ${primaryTextClasses}`}>{primaryText}</p>
          <p className={`tw-m-0 ${secondaryTextClasses}`}>Use code - {secondaryText}</p>
        </div>
      </StripIconInfoWrapper>{' '}
      {ctaTextOrIcon ? (
        <StripCTA onClick={() => onCtaClick(promoId)} ctaTextOrIcon={ctaTextOrIcon} />
      ) : null}
    </StripContainer>
  );
};

export default BasicPromoStrip;
