import { getCssFromThemeObject } from '@dotpe/kui/styles/theme/generate';
import { theme } from '@dotpe/kui/styles/theme/theme';

export const ThemeCss = () => {
  const cssTheme = getCssFromThemeObject(theme);

  return <>
    <style global jsx>{cssTheme}</style>
  </>;
};
