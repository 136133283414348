import { Dialog } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIconBlack from 'src/assets/svgExports/CloseIconBlack';
import OfferIcon from 'src/assets/svgExports/OfferIcon';
import Transition from 'src/components/BasicModal/Transition';
import { saveCart, setCart, togglePromoRemovalAlertModal } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { getThemeColor } from 'src/utils/getThemeColor';

const PromoRemovalAlertModal = () => {
  const dispatch = useDispatch();
  const themeColor = getThemeColor();

  const {
    promoRemovalAlertModalState: { show = false, cartDataAfterOfferRemoval = null },
    latestSyncedCart,
  } = useSelector((state: RootState) => ({
    promoRemovalAlertModalState: state.commonReducer?.promoRemovalAlertModalState || {},
    latestSyncedCart: state.commonReducer?.latestSyncedCart,
  }));

  const earlierAppliedPromoCode = (
    latestSyncedCart?.payment_offer?.promo_code ||
    latestSyncedCart?.store_offer?.promo_code
  )?.toUpperCase();

  useEffect(() => {
    if (!earlierAppliedPromoCode) {
      closeModal();
    }
  }, [earlierAppliedPromoCode]);

  function closeModal() {
    dispatch(
      togglePromoRemovalAlertModal({
        show: false,
        cartDataAfterOfferRemoval: null,
      })
    );
  }

  function onCancel() {
    dispatch(setCart(latestSyncedCart));
    closeModal();
  }

  function onProceed() {
    dispatch(saveCart(cartDataAfterOfferRemoval));
    closeModal();
  }

  const secondaryStyle = {
    color: themeColor,
    borderColor: themeColor,
  };

  const primaryStyle = {
    backgroundColor: themeColor,
  };

  return (
    !!earlierAppliedPromoCode && (
      <Dialog
        open={show}
        classes={{
          paper: `tw-m-0 ${IS_DESKTOP ? 'tw-relative tw-rounded-[8px]' : 'tw-absolute tw-left-0 tw-right-0 tw-bottom-0 tw-rounded-[8px_8px_0px_0px]'} tw-px-[24px] tw-py-[32px] md:tw-px-[30px] tw-rounded-[8px] tw-w-full md:tw-w-[560px]`,
        }}
        onClose={onCancel}
        TransitionComponent={Transition}
      >
        <>
          {IS_DESKTOP ? (
            <span
              className=" tw-absolute tw-right-[22px] tw-top-[22px] tw-cursor-pointer "
              onClick={onCancel}
            >
              <CloseIconBlack size={13} />
            </span>
          ) : null}
          <div className="tw-flex tw-flex-col tw-gap-[32px]">
            <div className="tw-flex tw-flex-col tw-gap-[24px]">
              <h3 className="tw-m-0 tw-text-[20px]/[24px] tw-font-semibold">
                Remove Item and discount?
              </h3>
              <p className="tw-m-0 tw-text-[12px]/[20px] tw-text-[#111C36] md:tw-text-[15px]/[24px]">
                There is a discount coupon applied on this product. Are you sure you want
                to remove this product and the discount with it?
              </p>
              <span className="tw-flex tw-items-center tw-gap-[6px] tw-text-[14px]/[17px] tw-font-semibold">
                <OfferIcon height={14} width={14} />
                {earlierAppliedPromoCode}
              </span>
            </div>
            <div className="tw-flex tw-flex-col-reverse tw-justify-end tw-gap-[12px] md:tw-flex-row md:tw-gap-[16px]">
              <button
                className="tw-cursor-pointer tw-rounded-[8px] tw-border-none tw-py-[15px] tw-text-[14px]/[17px] tw-font-semibold tw-text-white md:tw-w-[125px] md:tw-border md:tw-border-solid md:tw-bg-transparent md:tw-py-[12px] md:tw-text-[20px]/[24px]"
                style={IS_DESKTOP ? secondaryStyle : primaryStyle}
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="tw-cursor-pointer tw-rounded-[8px] tw-border tw-border-solid tw-bg-transparent tw-py-[15px] tw-text-[14px]/[17px] tw-font-semibold tw-text-white md:tw-w-[125px] md:tw-border-none md:tw-py-[12px] md:tw-text-[20px]/[24px]"
                style={IS_DESKTOP ? primaryStyle : secondaryStyle}
                onClick={onProceed}
              >
                {IS_DESKTOP ? 'Yes' : 'Remove Item'}
              </button>
            </div>
          </div>
        </>
      </Dialog>
    )
  );
};

export default PromoRemovalAlertModal;
