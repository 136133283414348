import { IS_SERVER } from './checkRenderEnv';
import { PREVIEW_PARAMS } from './constants';
import { getUrlParams } from './getUrlParams';

const checkIfThemeEditorOrPreview = () => {
  return (
    !IS_SERVER &&
    (window.location.href.includes('showroom-preview') ||
      !!getUrlParams([PREVIEW_PARAMS.THEME_PAGE])[PREVIEW_PARAMS.THEME_PAGE])
  );
};

export default checkIfThemeEditorOrPreview;
