import { useEffect, useState } from 'react';
import BasicModal from 'src/components/BasicModal';
import BasicPromoStrip from '../BasicPromoStrip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { getPromoDataById, togglePromoDetailModal } from 'src/redux/actions';
import { PromoEligibilies, RedirectionLinks } from './Common';

const PromoModal = () => {
  const dispatch = useDispatch();
  const {
    promoModalState: { show = false, promoId = null },
    storeId,
  } = useSelector((state: RootState) => ({
    promoModalState: state.commonReducer.promoDetailModalState || {},
    storeId: state.storeReducer.store.store_id,
  }));

  const [promoData, setPromoData] = useState(null);

  useEffect(() => {
    if (show) {
      getPromoData();
    }
  }, [show]);

  function getPromoData() {
    const params = {
      storeId,
      promoId,
    };
    dispatch(
      getPromoDataById(params, (data) => {
        setPromoData(data);
      })
    );
  }

  function onClose() {
    dispatch(togglePromoDetailModal({ show: false, promoId: null }));
  }

  return (
    <BasicModal
      show={show}
      onClose={onClose}
      heading="Offer Details"
      customClasses="tw-max-h-[466px]"
    >
      <div className="tw-flex tw-flex-col tw-gap-[24px] tw-px-[20px] tw-py-[16px] md:tw-px-[32px] md:tw-py-[24px]">
        <BasicPromoStrip
          primaryText={promoData?.description || ''}
          secondaryText={promoData?.code || ''}
          bgColor="#F0F0F0"
          showHoverEffect={false}
          customClasses="tw-rounded-[8px] md:tw-rounded-[0px] tw-px-[16px] md:tw-px-[24px] tw-py-[16px]"
          primaryTextClasses="tw-text-[14px]/[18px] md:tw-text-[16px]/[20px] tw-font-semibold"
          secondaryTextClasses="tw-text-[13px]/[20px] tw-font-normal md:tw-text-[15px]/[20px]"
        />
        <RedirectionLinks promoData={promoData} onClose={onClose} />
        <PromoEligibilies eligibiltyTexts={promoData?.offer_eligiblities || []} />
      </div>
    </BasicModal>
  );
};

export default PromoModal;
