import React, { FunctionComponent } from 'react';
import { StoreDetails } from '@/redux/reducers';
import { useSSRSelector } from '@/redux/ssrStore';
import { DEFAULT_SSR_IMAGE_URL } from '@/utils/constants/images';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';
import { getSocialMediaLink } from './utils';

interface RichSnippetStoreDetailsProps {
  store: StoreDetails;
}
export const RichSnippetStoreDetails: FunctionComponent<RichSnippetStoreDetailsProps> = (
  props
) => {
  const { storeFromSelector, storePolicy } = useSSRSelector((state) => ({
    storeFromSelector: state.storeReducer?.store,
    storePolicy: state.storePolicyReducer?.data[0] || null,
  }));
  const store = props.store || storeFromSelector;

  const storeData = {
    logo_image: store?.logo_image || DEFAULT_SSR_IMAGE_URL,
    store_url: store?.store_url,
    description: store?.description || '',
    store_name: store?.store_name,
    display_number: store?.display_phone,
  };
  const storeAddress = store?.address;
  const { storeEmail, storePhone } = useStoreContactDetails(true);
  const socialMediaLink = getSocialMediaLink(store?.social_media_profile_details);
  const isReturnPolicy = storePolicy?.additional_config_return_refund_policy?.is_accepting_returns;
  const refundDay = storePolicy?.additional_config_return_refund_policy?.day_count;
  const hasValidStoreData = storeData?.logo_image && storeData?.store_url;

  const storeSchema = {
    '@context': 'https://www.schema.org',
    '@type': 'OnlineStore',
    name: storeData?.store_name,
    description: storeData?.description,
    url: storeData?.store_url,
    logo: storeData?.logo_image,
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Service",
      ...(storePhone && { telephone: storePhone }),
      ...(storeEmail && { email: storeEmail }), 
    },
    ...(socialMediaLink.length && {sameAs: socialMediaLink}),
    ...(isReturnPolicy ?
      {hasMerchantReturnPolicy: {
        '@type': "MerchantReturnPolicy",
        returnPolicyCategory: "https://schema.org/MerchantReturnFiniteReturnWindow ",
        merchantReturnDays: refundDay,
        applicableCountry: "IN",
        returnPolicyCountry: "IN"
      }} :
      {hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        returnPolicyCategory: "https://schema.org/MerchantReturnNotPermitted",
      }}
      ),
      ...(storeAddress?.address_1 && {
        address: {
        "@type": "PostalAddress",
        streetAddress: storeAddress?.address_1 || "",
        addressLocality: storeAddress?.city || "",
        addressCountry: "IN",
        addressRegion: storeAddress?.state || "",
        postalCode: storeAddress?.pincode || "",
      }}), 
  }
  
  return hasValidStoreData ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(storeSchema),
      }}
    />
  ) : null;
};
