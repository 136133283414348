import { Fade, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  if (IS_DESKTOP) {
    return <Fade in={true} ref={ref} {...props} />;
  }
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Transition;
