import { createGlobalStyle } from 'styled-components';

export const GlobalStyleWrapper = createGlobalStyle<{
  fontStyles?: { isEnabled: boolean } | any;
  buttons?: { isEnabled: boolean } | any;
  imageCards?: { isEnabled: boolean } | any;
  inputs?: { isEnabled: boolean } | any;
}>((props) => {
  return `
    body {
    font-family: ${props.fontStyles?.isEnabled ? props.fontStyles?.body?.fontFamily : 'Inter'};
    letter-spacing: ${props.fontStyles?.isEnabled ? props.fontStyles?.body?.letterSpacing : 'unset'};

    h1,h2,h3,h4,h5,h6{
      &:not(.removeGlobalOverride){
        font-family: ${props.fontStyles?.isEnabled ? props.fontStyles?.heading?.fontFamily : 'Inter'};
        letter-spacing: ${props.fontStyles?.isEnabled ? props.fontStyles?.body?.letterSpacing : 'unset'};
        }
    }

    p {
      font-family: ${props.fontStyles?.isEnabled ? props.fontStyles?.body?.fontFamily : 'Inter'};
      letter-spacing: ${props.fontStyles?.isEnabled ? props.fontStyles?.body?.letterSpacing : 'unset'};
    }

    ${
      props.fontStyles.isEnabled
        ? `button {
              &:not(.removeGlobalOverride){
                  font-family: ${props.fontStyles?.[props.fontStyles?.buttonInherit]?.fontFamily};
                  letter-spacing: ${props.fontStyles?.body?.letterSpacing};
            }
        }
      `
        : ''
    }

    ${
      props.buttons.isEnabled
        ? `button {
              &:not(.removeGlobalOverride){
              border-radius: ${props.buttons?.style?.borderRadius};
            }
          }`
        : ''
    };

    ${
      props.imageCards?.isEnabled
        ? `img{
        &:not(.removeGlobalOverride){
          border-start-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-start-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        }

        .addTopLeftGlobalRadius{
          border-start-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-start-end-radius: unset;
          border-end-start-radius: unset;
          border-end-end-radius: unset;
        }

         .addTopRightGlobalRadius{
          border-start-start-radius:unset;
          border-start-end-radius:  ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-start-radius: unset;
          border-end-end-radius: unset;
        }

         .addBottomLeftGlobalRadius{
          border-start-start-radius: unset;
          border-start-end-radius: unset;
          border-end-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-end-radius: unset;
        }

         .addBottomRightGlobalRadius{
          border-start-start-radius: unset;
          border-start-end-radius: unset;
          border-end-start-radius: unset;
          border-end-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        }
    }
    .imgWrapperGlobalStyleMin{
        :not(.removeGlobalOverride){
        border-start-start-radius: 20%;
        border-start-end-radius: 20%;
        border-end-start-radius: 20%;
        border-end-end-radius: 20%;
      }
    }

    .imgWrapperGlobalStyle{
        :not(.removeGlobalOverride){
        border-start-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        border-start-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        border-end-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        border-end-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        }
          .addTopLeftGlobalRadius{
          border-start-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-start-end-radius: unset;
          border-end-start-radius: unset;
          border-end-end-radius: unset;
        }

         .addTopRightGlobalRadius{
          border-start-start-radius:unset;
          border-start-end-radius:  ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-start-radius: unset;
          border-end-end-radius: unset;
        }

         .addBottomLeftGlobalRadius{
          border-start-start-radius: unset;
          border-start-end-radius: unset;
          border-end-start-radius: ${props.imageCards?.style?.borderRadius || '0px'};
          border-end-end-radius: unset;
        }

         .addBottomRightGlobalRadius{
          border-start-start-radius: unset;
          border-start-end-radius: unset;
          border-end-start-radius: unset;
          border-end-end-radius: ${props.imageCards?.style?.borderRadius || '0px'};
        }
      }`
        : ''
    }
    
    ${
      props.inputs?.isEnabled
        ? `input:not(.removeGlobalOverride){
            border-radius: ${props.inputs?.style?.borderRadius || '0px'};
        }`
        : ''
    }
    
  }
`;
});
