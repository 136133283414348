import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import router from 'next/router';
import { getRoute } from 'src/utils/routes';

export const RedirectionLink = ({ name, route, onClose }) => {
  const domain = useSelector((state: RootState) => state.storeReducer.store.domain);

  const onLinkClick = () => {
    router.push(getRoute(route, domain));
    onClose();
  };

  return (
    <button
      className="tw-flex tw-cursor-pointer tw-border tw-border-solid tw-border-[#DBDBDB] tw-bg-transparent tw-px-[12px] tw-py-[4px] tw-text-[13px]/[20px] tw-text-black tw-no-underline hover:tw-bg-[#F7F7F7]"
      onClick={onLinkClick}
    >
      {name}
    </button>
  );
};

export const RedirectionLinks = ({ promoData, onClose }) => {
  const requiredLinksData = promoData?.required_entity_chips || [];
  const rewardListData = promoData?.reward_entity_chips || [];

  const [showMoreRequired, setShowMoreRequired] = useState(false);
  const [showMoreReward, setShowMoreReward] = useState(false);

  const renderRequiredLinks = (requiredLinks) => {
    const totalRequiredLinks = requiredLinks?.length;
    return (
      <>
        {requiredLinks
          .slice(0, showMoreRequired ? totalRequiredLinks : 6)
          .map((linkData, index) => (
            <RedirectionLink
              key={index}
              name={linkData?.text}
              route={linkData?.url}
              onClose={onClose}
            />
          ))}
        {totalRequiredLinks > 6 && (
          <span
            className="tw-flex tw-cursor-pointer tw-items-center tw-text-[13px]/[20px] tw-font-semibold"
            onClick={() => setShowMoreRequired(!showMoreRequired)}
          >
            {showMoreRequired ? 'Less' : 'More'}
          </span>
        )}
      </>
    );
  };

  const renderRewardChips = (rewardChips) => {
    const totalRewardChips = rewardChips?.length;

    return (
      <>
        {rewardChips
          .slice(0, showMoreReward ? totalRewardChips : 6)
          .map((rewardData) => rewardData?.text || '')
          .join(', ')}
        {totalRewardChips > 6 && (
          <span
            className="tw-flex tw-cursor-pointer tw-items-center tw-text-[13px]/[20px] tw-font-semibold"
            onClick={() => setShowMoreReward(!showMoreReward)}
          >
            ...{showMoreReward ? 'Less' : 'More'}
          </span>
        )}
      </>
    );
  };

  return (
    <>
      {!!requiredLinksData?.length && (
        <div className="tw-flex tw-flex-col tw-gap-[8px] md:tw-gap-[12px]">
          <p className="tw-m-0 tw-text-[14px]/[20px] tw-font-semibold">
            {promoData?.requirement_header_text || ''}
          </p>
          <div className="tw-flex tw-flex-wrap tw-gap-[8px]">
            {renderRequiredLinks(requiredLinksData)}
          </div>
        </div>
      )}
      {!!rewardListData?.length && (
        <div className="tw-mb-[8px] tw-flex tw-flex-col tw-gap-[8px] md:tw-gap-[12px]">
          <p className="tw-m-0 tw-text-[14px]/[20px] tw-font-semibold">
            {promoData?.reward_header_text || ''}
          </p>
          <div className="tw-flex tw-flex-wrap tw-text-[13px]/[20px]">
            {renderRewardChips(rewardListData)}
          </div>
        </div>
      )}
    </>
  );
};

export const PromoEligibilies = ({ eligibiltyTexts }) => {
  return (
    !!eligibiltyTexts?.length && (
      <div className="tw-flex tw-flex-col tw-gap-[8px] md:tw-gap-[12px]">
        <p className="tw-m-0 tw-text-[14px]/[20px] tw-font-semibold">Offer Eligibility</p>
        <ul className="tw-ml-[12px] tw-list-outside">
          {eligibiltyTexts.map((text, index) => (
            <li className="tw-text-[13px]/[22px] md:tw-text-[14px]/[22px]" key={index}>
              {text}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};
